import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import cssModule from './index.module.scss';
import { translations } from './translations';
import { serializers } from '../../utils/blockContent';
import { SEO, Layout, Header, Footer } from '../../components';

import Flex from '../../components/UI/Flex';
import Container from '../../components/UI/Container';

export default ({ pageContext: ctx }) => {
  const { translationPath, toc, metadata, textBlock, i18n_lang: lang } = ctx;
  //
  console.log({ textBlock });
  return (
    <Layout>
      <SEO {...metadata.metadata} />
      <Header
        lang={lang}
        translationPath={translationPath}
        {...{ lang, heading: translations[lang].heading }}
      />
      <section className={cssModule.toc}>
        <Container>
          <Flex className="flex-container" justifyContent="center" alignItems="center">
            <Flex small="100%" tablet="80%" xlarge="33%">
              {/* <p>{toc}</p> */}
              {textBlock.description && (
                <BlockContent serializers={serializers} blocks={textBlock.description} />
              )}
            </Flex>
          </Flex>
        </Container>
      </section>
      <Footer lang={lang} />
    </Layout>
  );
};
